
import { defineComponent, computed, PropType } from 'vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import { Images } from '../../types';

export default defineComponent({
  components: {
    ResponsiveImage,
  },
  props: {
    images: {
      type: Object as PropType<Images>,
    },
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const textWithEllipsis = (text) => {
      const length = 27;
      const shortText = text.slice(0, length);
      return text.length > length ? `${shortText}...` : text;
    };

    const responsiveImage = computed(() => ({
      alt: props.title,
      mobile: {
        x1: props.images.mobile_s.download_url,
        x2: props.images.mobile_m.download_url,
        x3: props.images.mobile_m.download_url,
      },
      desktop: {
        x1: props.images.mobile_s.download_url,
        x2: props.images.mobile_m.download_url,
        x3: props.images.mobile_m.download_url,
      },
    }));

    return {
      textWithEllipsis,
      responsiveImage,
    };
  },
});
