
import { defineComponent, PropType, ref } from 'vue';
import ArchiveTeaser from '@/modules/stories/components/ArchiveTeaser/ArchiveTeaser.vue';
import Icon from '@/components/Icon/Icon.vue';
import Badge from '@/components/Badge/Badge.vue';

import { Story } from '../../types';

export default defineComponent({
  components: {
    Badge,
    ArchiveTeaser,
    Icon,
  },
  props: {
    categoryName: {
      type: String,
      required: true,
    },
    teasers: {
      type: Object as PropType<Story[]>,
      required: true,
    },
    handleTeaserClick: {
      type: Function as PropType<(story: Story) => void>,
      required: true,
    },
  },
  setup(props) {
    const SPACE = 186 + 16; // ArchiveTeaser width + margin right
    const current = ref(0);

    const next = () => {
      current.value =
        current.value <= props.teasers.length - 2
          ? current.value + 1
          : current.value;
    };
    const prev = () => {
      current.value = current.value > 0 ? current.value - 1 : current.value;
    };

    return {
      current,
      next,
      prev,
      SPACE,
    };
  },
});
