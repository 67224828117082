
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isNormalFont: {
      type: Boolean,
    },
    category: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
});
