import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47b17758"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "story-introblock bg-primary-soft-white" }
const _hoisted_2 = { class: "pt-2 pb-10 pl-8" }
const _hoisted_3 = {
  key: 0,
  class: "story-introblock__info mb-1.5 text-tints-slate-50"
}
const _hoisted_4 = { class: "story-introblock__headline mb-5 xl:mb-7 break-words font-normal text-fs-36-lh-44-ls-0.01 flex items-start justify-between text-primary-slate" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.category)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.category), 1))
        : _createCommentVNode("", true),
      _createElementVNode("h2", _hoisted_4, [
        _createElementVNode("span", {
          innerHTML: _ctx.data?.header
        }, null, 8, _hoisted_5),
        (_ctx.data?.images?.mobile_s?.download_url)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "story-introblock__image ml-10 object-cover",
              src: 
            _ctx.data?.images.mobile_s.download_url ||
            _ctx.data?.images.mobile_m.download_url ||
            _ctx.data?.images.mobile_m.download_url ||
            _ctx.data?.images.desktop_xl.download_url ||
            _ctx.data?.images.desktop_xl.download_url ||
            _ctx.data?.images.desktop_xxl.download_url
          
            }, null, 8, _hoisted_6))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["story-introblock__text text-sm tracking-wide text-primary-slate pr-8", [_ctx.isNormalFont ? 'font-normal' : 'font-bold']]),
        innerHTML: _ctx.data?.content
      }, null, 10, _hoisted_7)
    ])
  ]))
}