import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33eac60c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "archive-teaser lg:cursor-pointer" }
const _hoisted_2 = { class: "absolute bottom-0 left-0 z-10 pl-s pr-m pb-2 text-primary-soft-white" }
const _hoisted_3 = { class: "text-fs-18-lh-28-ls-0.2 leading-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ResponsiveImage, {
      class: "absolute w-full h-96 z-0",
      lightDarkOverlay: "",
      responsiveImageSet: _ctx.responsiveImage
    }, null, 8, ["responsiveImageSet"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.textWithEllipsis(_ctx.title)), 1)
    ])
  ]))
}